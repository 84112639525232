import { Link } from 'gatsby';
import React from 'react';
import PromoteCta from '../components/common/PromoteCta';
import DiaryCard from '../components/illustrations/DiaryCard';

import Layout from '../components/layout';
import SEO from '../components/seo';

function CreatingFaith() {
  return (
    <Layout>
      <SEO
        keywords={[`regal project`, `ireland`, `focus`, `lone parents`]}
        title='Creating Faith'
      />
      <section id='about' className='bg-white w-full min-h-screen '>
        <div className='py-16 md:py-32 md:w-2/3  mx-auto px-8  md:px-0'>
          <header className='text-4xl md:text-6xl font-serif text-center mb-8 mt-16 md:mt-0 '>
            <div className='w-1/2 md:w-1/3 mx-auto'>
              <DiaryCard />{' '}
            </div>{' '}
            <h2>
              <span className='alt-heading-bg'>Creating</span> Faith
            </h2>
          </header>
          <div className='content max-w-3xl mx-auto space-y-4 md:text-xl'>
            <p>
              {' '}
              The REGAL Project in Ireland worked with women with experience of
              homelessness and/or housing insecurity and have a care
              responsibility. The project was led by Focus Ireland, a national
              housing and homeless NGO working to prevent individuals, young
              people and families from becoming homeless and support them out of
              homelessness.
            </p>
            <p>
              The majority of participants were recruited through Focus
              Ireland’s services. The project worked with a diverse, core group
              of 15 women in 12 focus groups between November 2019 and March
              2020 before public health restrictions came into effect as a
              result of Covid-19.
            </p>{' '}
            <p>
              The focus groups were held in the Outhouse community resource
              centre in Dublin city, where each week the group were warmly
              welcomed and accommodated. Childcare was provided in a separate
              room at the same venue for the women who had young children not
              attending school. Most of the childcare was provided by two
              voluntary childcare workers.
            </p>
            <p>
              As a way of exploring the research questions amongst a diverse
              group in general rather than personal terms, the character ‘Faith’
              was introduced. Faith represented the women’s experiences,
              feelings and aspirations and would ultimately speak to
              policymakers on their behalf. Faith had a physical presence in all
              of the focus groups through the form of a letter box. Through
              this, the women could speak directly to her with letters or notes.
            </p>
            <p>
              {' '}
              Getting to know Faith and developing a picture of her life allowed
              the group to connect and importantly to build a sense of
              solidarity around the barriers in Faith’s life and her
              aspirations. Faith was like them and they wanted the best for her.
              Faith’s diary represents the voices and experiences of the women
              in this project.
            </p>
            <p>
              {' '}
              Faith was inspired by{' '}
              <a href='http://www.fionawhelan.com/' className='faith-link'>
                Fiona Whelan
              </a>
              , a socially engaged artist and educator, who worked as a
              consultant on the project. Fiona used a similar methodology in
              previous work she undertook with marginalised groups in Dublin.
            </p>
          </div>
        </div>
      </section>

      {/* How We Created Faith */}
      <section className='grid grid-cols-2 w-full'>
        {/* About Regal */}
        <Link className='col-span-2 md:col-span-1  ' to='/about-regal'>
          <div className='bg-faith-orange '>
            <div className='py-16 md:py-32'>
              {' '}
              <h5 className='text-2xl md:text-5xl text-center font-bold   text-white'>
                About Regal
              </h5>
            </div>
          </div>
        </Link>
        <Link className='col-span-2 md:col-span-1' to='/about-focus'>
          <div className='bg-faith-yellow '>
            <div className='py-16 md:py-32'>
              {' '}
              <h5 className='text-2xl md:text-5xl text-center font-bold  text-white'>
                About Focus Ireland
              </h5>
            </div>
          </div>
        </Link>
      </section>
      <PromoteCta />
    </Layout>
  );
}

export default CreatingFaith;
